import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CONVERSATIONS_ROUTE } from '../../conversation/constants/route.constants';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInRedirectGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(): Observable<boolean> | boolean {
    if (this.authService.isLoggedIn()) {
      this.router.navigate([CONVERSATIONS_ROUTE.ROOT]);
      return false;
    }
    return true;
  }
}
