export enum Mamahood {
  parent = 'parent',
  expecting = 'expecting',
  trying = 'trying',
  inPlans = 'inPlans',
}
export class MembershipIntroIntroLocationGeometry {
  latitude?: string
  longitude?: string
}
export class MembershipIntroLocation {
  address: string = null
  formattedAddress?: string = null
  apartment: string = null
  city: string = null
  country: string = null
  state: string = null
  coreCity: CoreCity | string = null
  zipCode: string = null
  isResidentUS?: boolean = null
  geometry?: MembershipIntroIntroLocationGeometry = new MembershipIntroIntroLocationGeometry()
}
export class MembershipIntro {
  firstName: string = null
  lastName: string = null
  email: string = null
  location: MembershipIntroLocation = new MembershipIntroLocation()
  mamahood: Mamahood[] = []
}
export class CoreCity {
  _id: string
  title: string
}
