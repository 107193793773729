import { Observable } from 'rxjs';

export abstract class AvatarUploaderApi {
  uploading$: Observable<boolean>;

  uploadAvatar$: Observable<any>;

  avatarUploaded$: Observable<void>;

  abstract upload(payload: any): void;

  abstract avatarUploaded(): void;
}
