import { createAction, props } from '@ngrx/store';
import {
  ActiveUserState,
  Agreement,
  EditProfileMessage,
  PaymentHistory,
} from '@shared/modules/active-user/store/active-user.state';
import { QueryParams } from '@shared/models/query-params';
import { StatusReasonParamsStructured } from '@shared/modules/active-user/interfaces/reason.interface';
import { UserStatus } from '@shared/modules/active-user/interfaces/user-status';
import { StatusUpdateReq } from '@shared/modules/active-user/interfaces/status.interface';
import { HttpErrorResponse } from '@angular/common/http';

export const loadActiveUser = createAction('[ActiveUser] Load Active User');

export const loadActiveUserSuccess = createAction(
  '[ActiveUser] Load Active User Success',
  props<{ user: ActiveUserState }>()
);
export const loadActiveUserFailure = createAction('[ActiveUser] Load Active User Failure', props<{ error: any }>());

export const updateActiveUser = createAction('[ActiveUser] Update Active User', props<{ profile: any }>());

export const updateActiveUserFailure = createAction(
  '[ActiveUser] Load Active User Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setProfileMessage = createAction(
  '[ActiveUser] Set Edit Profile Message',
  props<{ payload: EditProfileMessage }>()
);

export const clearEditProfileMessage = createAction('[ActiveUser] Clear Edit Profile Message');

export const loadStatusReasonParams = createAction(
  '[ActiveUser] Load status change reason params',
  props<{ payload: { status: UserStatus.PAUSED | UserStatus.CANCELED } }>()
);

export const loadStatusReasonParamsSuccess = createAction(
  '[ActiveUser] Load status change reason params Success',
  props<{ payload: StatusReasonParamsStructured }>()
);

export const updateStatus = createAction(
  '[ActiveUser] update membership status',
  props<{ payload: StatusUpdateReq }>()
);

export const putNextSubscription = createAction(
  '[ActiveUser] Put next subscription',
  props<{ payload: { plan: string; coupon?: string } }>()
);

export const logOut = createAction('[ActiveUser] Log Out');

export const deleteUserData = createAction('[ActiveUser] Delete user data');

export const uploadAvatar = createAction('[ActiveUser] upload avatar', props<{ file: File }>());

export const avatarUploaded = createAction('[ActiveUser] avatar uploaded');

export const loadPayments = createAction('[ActiveUser] Load user payment history', props<QueryParams>());

export const loadPaymentsSuccess = createAction(
  '[ActiveUser] Load user payment history success',
  props<{ payments: PaymentHistory }>()
);

export const loadBillingInfo = createAction('[ActiveUser] Load user billing information');

export const addPaymentSource = createAction(
  '[ActiveUser] Add payment source',
  props<{ payload: { cardToken: string } }>()
);

export const loadBillingInfoSuccess = createAction(
  '[ActiveUser] Load user billing information success',
  props<{ billingInformation: PaymentHistory }>()
);

export const updateAgreements = createAction(
  '[ActiveUser] Update Agreements',
  props<{ agreement: Partial<Agreement> }>()
);

export const upsertUser = createAction('[ActiveUser] Upsert User', props<{ payload: ActiveUserState }>());

// export const deleteAuthTokens = createAction('[LogIn] Delete Auth Tokens')

// export const logIn = createAction('[LogIn] Log In', props<Login>())
