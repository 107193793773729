import { Injectable } from '@angular/core'
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators'
import { HttpStatus } from '@shared/consts'
import { AuthService } from '../../../modules/auth/services/auth.service'
import { SnackbarMessage, SnackbarService } from '@shared/components/hm-snackbar'

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false
  private refreshTokenSubject = new BehaviorSubject<string>(null)

  constructor(private _authSrv: AuthService, private _snackbarSrv: SnackbarService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any> | any> {
    if (this._authSrv.jwtToken) {
      request = this.addToken(request, this._authSrv.jwtToken)
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === HttpStatus.UNAUTHORIZED
        ) {
          if (this._authSrv.jwtRefreshToken) return this.handleExpiredJwt(request, next)
          if (this._authSrv.isLoggedIn()) {
            this._snackbarSrv.error(SnackbarMessage.SESSION_EXPIRED);
            return this._authSrv.logOut()
          }
        }

        return throwError(error)
      })
    )
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  private handleExpiredJwt(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (!this.isRefreshing) {
      this.isRefreshing = true
      this.refreshTokenSubject.next(null)

      return this._authSrv
        .refreshToken()
        .pipe(
          switchMap((token) => {
            this.isRefreshing = false
            this.refreshTokenSubject.next(token.accessToken)
            return next.handle(this.addToken(request, token.accessToken))
          })
        )
        .pipe(
          catchError((err) => {
            this._authSrv.logOut()
            return throwError(err)
          })
        )
    } else {
      return this.refreshTokenSubject
        .pipe(filter((token) => token != null))
        .pipe(take(1))
        .pipe(switchMap((jwt) => next.handle(this.addToken(request, jwt))))
    }
  }
}
