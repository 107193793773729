import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorInterceptor } from '@shared/interceptors/error-interceptor/error.interceptor';
import { HmSnackbarModule } from '@shared/components/hm-snackbar/hm-snackbar.module';
import { TokenInterceptor } from '@shared/interceptors/token-interceptor/token-interceptor.service';
import { HideProdModule } from '@shared/directives/hide-prod/hide-prod.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AvatarUploaderApi } from '@shared/api/avatar-uploader.api';
import { AvatarUploaderService } from '@shared/services/avatar-uploader/avatar-uploader.service';
import { ViewPostFooterStateApi } from './modules/view-post/api/view-post-footer-state.api';
import { ViewPostFooterStateService } from './modules/view-post/services/view-post-footer-state.service';
import { AuthorizedContentWrapperApi } from './modules/authorized-content-wrapper/api/authorized-content-wrapper.api';
import { AuthorizedContentWrapperService } from './modules/authorized-content-wrapper/services/authorized-content-wrapper.service';
import { ConversationsApi } from '@shared/modules/hm-posts-feed/api/conversations.api';
import { ConversationsService } from '@shared/modules/hm-posts-feed/services/conversations.service';
import { ConversationsNotificationsCountersService } from '@shared/services/conversations-notifications-counters/conversations-notifications-counters.service';
import { ConversationsNotificationsCountersApi } from '@shared/api/conversations-notifications-counters.api';
import { SidenavStateApi } from './modules/authorized-content-wrapper/api/sidenav-state.api';
import { SidenavStateService } from './modules/authorized-content-wrapper/services/sidenav-state.service';
import { CoreModule } from './core/core.module';
import { MaterialModule } from '@material/material.module';
import { HmSpinnerModule } from '@shared/components/hm-spinner/hm-spinner.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([]),
    HttpClientModule,
    HmSnackbarModule,
    HmSpinnerModule,
    MaterialModule,
    HideProdModule,
  ],
  providers: [
    {
      provide: AvatarUploaderApi,
      useClass: AvatarUploaderService,
    },
    {
      provide: AuthorizedContentWrapperApi,
      useClass: AuthorizedContentWrapperService,
    },
    {
      provide: SidenavStateApi,
      useClass: SidenavStateService,
    },
    { provide: ConversationsApi, useClass: ConversationsService },
    { provide: ConversationsNotificationsCountersApi, useClass: ConversationsNotificationsCountersService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorInterceptor,
    },
    { provide: ViewPostFooterStateApi, useClass: ViewPostFooterStateService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
