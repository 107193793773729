import { Status, StatusPeriod } from './status.interface'
import { UserStatus } from '@shared/modules/active-user/interfaces/user-status'

export interface Reason {
  _id: string
  title: string
  order: string
}

export class StatusReasonParams {
  reasons: Reason[] = []
  period?: {
    limit: StatusPeriod
    passed: StatusPeriod
    available: StatusPeriod
  } = null
  status: Status = null
}

export class StatusReasonParamsStructured {
  [UserStatus.CANCELED]? = new StatusReasonParams();
  [UserStatus.PAUSED]? = new StatusReasonParams();
}
