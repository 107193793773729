import { Injectable } from '@angular/core';
import { ApiService } from '@shared/services/http/api/api.service';
import { Login, loginResponseModel } from '../pages/login/models/login.model';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';
import { AppConfig } from '@config';
import { AuthRoute } from '../const';
import { JwtHelperService } from '@auth0/angular-jwt';

const TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public jwtSrv: JwtHelperService = new JwtHelperService();

  constructor(
    private _apiService: ApiService,
    private _router: Router,
    private _analyticSrv: AnalyticsService,
  ) {}

  logIn(loginData: Login): Observable<loginResponseModel> {
    const { email, password, rememberMe } = loginData;
    return this._apiService.post('user/login', { email, password }).pipe(
      tap(({ accessToken, refreshToken }: loginResponseModel) => {
        this._analyticSrv.resetSession();
        this.saveTokens({
          accessToken,
          refreshToken: rememberMe ? refreshToken : null,
        });
        const { sub, email } = this.jwtSrv.decodeToken(accessToken);
        this._analyticSrv.emitCommonEvent(`${email} (${sub})`, { label: 'login', category: 'session' });
      })
    );
  }

  isLoggedIn() {
    return !!this.jwtToken;
  }

  forgotPassword(email: string): Observable<any> {
    return this._apiService.post('user/forgot-password', { email });
  }

  resetPassword(data: { password: string; token: string }): Observable<any> {
    return this._apiService.post('user/reset-password', data);
  }

  // ['production', 'staging'].includes(AppConfig.env)
  logOut(withWP = false): Observable<any> {
    if (!withWP) {
      this.resetAuth();
      return of(this._router.navigate([AuthRoute.LOGIN]));
    }
    // TODO resolve if needs sync with WP again
    // https://app.clickup.com/t/1jtjgmk

    // return this._apiService.get('user/logout').pipe(
    //   tap(() => {
    //     this.resetAuth();
    //     AuthService.logOutWP();
    //     // this._router.navigate([AuthRoute.LOGIN])
    //   })
    // );
  }

  saveTokens = (tokens: loginResponseModel) => {
    localStorage.setItem(TOKEN, tokens.accessToken);
    if (tokens.refreshToken) localStorage.setItem(REFRESH_TOKEN, tokens.refreshToken);
  };

  get jwtToken() {
    return localStorage.getItem(TOKEN);
  }

  get jwtRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  refreshToken(): Observable<loginResponseModel> {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    return this._apiService.post('user/refresh-token', { refreshToken }).pipe(tap(this.saveTokens));
  }

  private resetAuth() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    this._analyticSrv.resetSession();
  }

  private static logOutWP() {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = AppConfig.wpDomain;
    const input = document.createElement('input');
    input.name = 'logout';
    input.value = 'true';
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
  }
}
