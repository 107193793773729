import { SearchParams } from '@shared/models';
import { Post } from '@shared/models/post.models';
import { PaginatedData } from '@shared/models/query-params';
import { Observable } from 'rxjs';

export abstract class ConversationsApi {
  abstract getPost(id: string): Observable<Post>;

  abstract getPosts(queryParams: SearchParams): Observable<PaginatedData<Post>>;

  abstract getNewPostsCount(searchParams: SearchParams): Observable<number>;

  abstract deletePost(id: string): Observable<Post>;

  abstract follow(id: string): Observable<{ follow: boolean }>;

  abstract unfollow(id: string): Observable<{ follow: boolean }>;

  abstract pinPost(postId: string, communityId: string): Observable<any>;

  abstract unpinPost(postId: string, communityId: string): Observable<any>;
}
