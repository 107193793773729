import { Coupon } from '@shared/interfaces';
import {
  Mamahood,
  MembershipIntroLocation,
} from '../../../../modules/membership/pages/membership-intro/interfaces/membership-intro.interface';
import {
  MembershipAboutMeReferral,
  MembershipAboutMeWebsite,
} from '../../../../modules/membership/pages/membership-about-me/interfaces/membership-about-me.interface';
import { Career, socialNetwork } from '../../../../modules/profile/store/edit-membership.interfaces';
import { PaymentPlan } from '@shared/interfaces/payment/payment-plans.interface';
import { StatusReasonParamsStructured } from '@shared/modules/active-user/interfaces/reason.interface';
import { UserStatus } from '@shared/modules/active-user/interfaces/user-status';
import { Support } from '../../../../modules/sign-up/pages/sign-up-details/interfaces/sign-up-details.interface';
import { ParenthoodType } from '@shared/interfaces/parenthood.interfaces';
import { Community } from '@shared/interfaces/community/community.interfaces';

export class ActiveUserState {
  firstName: string = null;
  lastName: string = null;
  email: string = null;
  emails: string[] = [];
  avatar: AvatarInterface;
  agreement: Agreement;
  payment: Payment;
  isTech: boolean;
  isConv?: boolean;
  demographic: Demographic = new Demographic();
  education: Education = new Education();
  personality: Personality = new Personality();
  connection: Connection = new Connection();
  birthday: string;
  phone: string;
  location: MembershipIntroLocation = new MembershipIntroLocation();
  referral: MembershipAboutMeReferral;
  mamahood: Mamahood[] = [];
  _id: string;
  reasonToJoin: string;
  socialNetworks: socialNetwork[] = [];
  websites: MembershipAboutMeWebsite[] = [];
  career: Career = new Career();
  support: Support;
  status: UserStatus;
  createdAt: string;
  updatedAt: string;
  privacy?: any;
  editProfileMessage?: EditProfileMessage;
  statusReasonParamsStructured = new StatusReasonParamsStructured();
  paymentHistory = new PaymentHistory();
  billingInformation = new BillingInformation();
  subscription = new SubscriptionInfo();
}

export class MemberProfile extends ActiveUserState {
  session?: {
    createdAt: string;
    updatedAt: string;
    _id: string;
  };

  communities?: Community[];
}

export class UserState extends ActiveUserState {}

export class Connection {
  appOfferings: [];
  help: { needs: string[]; offers: string[] };
  needs: string[];
  offers: string[];
  reasonDescription: string;
}

export class Personality {
  credo: string;
  prefer: string[];
  self: string[];
}

export class Education {
  college: string;
  level: string;
}

export class Child {
  firstName: string;
  lastName: string;
  gender: string;
  birthday: string;
}

export class Demographic {
  householdIncome: string;
  marital: string;
  gender: string;
  nationality: string;
  children: Child[] = [];
  parenthood: ParenthoodType[] = [];
}

export class BillingInformation {
  customerId: string;
  sources: BillingSource[] = [];
  livemode: boolean;
  nextRenewal: Date;
}

export class PaymentHistory {
  currPage: number;
  totalPages: number;
  totalItems: number;
  data: Charge[] = [];
}

export interface Charge {
  _id: string;
  createdAt: string;
  updatedAt: string;
  isPrivate: boolean;
  amount: number;
  productId: string;
  product: string;
  type: string;
  source: BillingSource;
  description: string;
  status: 'fail' | 'charged';
}

export interface BillingSource {
  id: string;
  object: string;
  brand: string;
  last4: string;
  customer: string;
  customerId?: string;
  isDefault?: boolean;
}

export interface Reasons {
  reasons: Reason;
  availablePeriod: AvailabilityPeriod;
}

export interface AvailabilityPeriod {
  unit: string;
  amount: number;
}

export interface Reason {
  _id: string;
  createdAt: string;
  updatedAt: string;
  isPrivate: boolean;
  title: string;
  order: number;
  subject: string;
  keys: string[];
}

export interface Payment {
  plan: PaymentPlan;
  coupon: Coupon;
  customerId: string;
}

export interface AvatarInterface {
  _id: string;
  url: string;
  filename: string;
}

export interface Agreement {
  conversation: boolean;
  profileCompleted: boolean;
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  PAUSED = 'paused',
  CANCELED = 'canceled',
}

export interface Subscription {
  _id: string;
  plan: PaymentPlan;
  coupon: Coupon;
  status: SubscriptionStatus;
  paymentAt: Date;
  renewalAt: Date;
  startedAt: Date;
  endAt: Date;
  endedAt: Date;
  pausedUntil: Date;
}

export class SubscriptionInfo {
  current: Subscription;
  next: Subscription;
}

export class MembershipIntro {
  firstName: string = null;
  lastName: string = null;
  email: string = null;
  location: MembershipIntroLocation = new MembershipIntroLocation();
  mamahood: Mamahood[] = [];
}

export interface EditProfileMessage {
  isOpen: boolean;
  message: string;
  timeOut?: number;
}
