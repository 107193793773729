import { Directive, TemplateRef, ViewContainerRef } from '@angular/core'
import { environment } from '../../../../environments/environment'

@Directive({
  selector: '[hideProd]',
})
export class HideProdDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (!environment.production) {
      this.viewContainerRef.createEmbeddedView(this.templateRef)
    }
  }
}
