import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, of } from 'rxjs'

import { AppConfig } from '@config'
import { QueryStringService } from '@shared/services/http/query-string/query-string.service'
import { catchError, retry } from 'rxjs/operators'

export interface ApiInputOptions {
  headers?: any
  params?: any
  responseType?: 'json'
  // exclude specific errors from interceptor and prevent to show common popup
  excludeErrors?: ({ key: 'statusCode' | 'message', value: string } | '*')[]
}

interface IRequestOptions {
  headers: HttpHeaders
  params: HttpParams
  body: HttpParams
  responseType: 'json' // 'json' | 'blob' | 'text' and so on
}

export interface AuthResp {
  accessToken: string
  refreshToken: string
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private querySrv: QueryStringService) {}

  protected getHeaders(headers: { [key: string]: string } = {}): HttpHeaders {
    // const headers = {'X-API-Version': AppConfig.apiVersion};
    // if (this.sessionSrv.getToken()) headers['Authorization'] = `Bearer ${this.sessionSrv.getToken()}`;

    return new HttpHeaders(headers)
  }

  protected prepareOptions(options: ApiInputOptions & { body?: any } = {}): IRequestOptions {
    const { headers = {}, params, body, responseType, excludeErrors = [] } = options;
    return {
      headers: this.getHeaders({ ...headers, excludeErrors: JSON.stringify(excludeErrors) }),
      params: this.querySrv.buildParams(params),
      body: body || {},
      responseType: responseType || 'json',
    };
  }

  get(path: string, options?: ApiInputOptions): Observable<any> {
    // Don't catchError here!
    // Sometimes needs handle err in component
    return this.http.get(
      `${AppConfig.apiUrl}${path}`,
      this.prepareOptions(options)
    )
  }

  post(path: string, body, options?: ApiInputOptions): Observable<any> {
    return this.http.post(
      `${AppConfig.apiUrl}${path}`,
      body,
      this.prepareOptions(options)
    )
  }

  put(path: string, body, options?: ApiInputOptions): Observable<any> {
    return this.http.put(
      `${AppConfig.apiUrl}${path}`,
      body,
      this.prepareOptions(options)
    )
  }

  patch(path: string, body = {}, options?: ApiInputOptions): Observable<any> {
    return this.http.patch(
      `${AppConfig.apiUrl}${path}`,
      body,
      this.prepareOptions(options)
    )
  }

  delete(path: string, options?: ApiInputOptions): Observable<any> {
    return this.http.delete(
      `${AppConfig.apiUrl}${path}`,
      this.prepareOptions(options)
    )
  }
}
