import { Injectable } from '@angular/core';
import * as Fingerprint2 from 'fingerprintjs2';
import { Options } from 'fingerprintjs2';
import { Observable, Subject } from 'rxjs';

import { ApiService } from '@shared/services/http/api/api.service';
import { CreateUserReq } from '../../../modules/membership/services/membership.service';
import { AppConfig } from '@config';
import { SignUpDetails } from '../../../modules/sign-up/pages/sign-up-details/interfaces/sign-up-details.interface';
import { EventLogOptions } from '@shared/services/analytics/interfaces/event-log.interface'

declare let gtag: Function;
declare let fbq: Function;

class EventOptions {
  category?: string;
  action?: string;
  label?: string;
  value?: string;
}

interface FingerprintReq {
  hashId: string;
  userAgent: string;
  language: string;
  deviceMemory: number;
  hardwareConcurrency: number;
  screenResolution: number[];
  timezoneOffset: number;
  timezone: string;
  // touchSupport: string;
}

const selectKeys: string[] = [
  'userAgent',
  'language',
  'deviceMemory',
  'hardwareConcurrency',
  'screenResolution',
  'timezoneOffset',
  'timezone',
  // 'touchSupport',
];

interface AnalyticSession {
  _id: string;
}

interface PageViewParam {
  path: string;
  force?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _fingerprintData: FingerprintReq = null;
  private _sessionReadyEmitter: Subject<AnalyticSession>;
  private _sessionId: string;

  constructor(private _apiSrv: ApiService) {
    if (typeof window['dataLayer'] !== 'undefined') window['dataLayer'].push({'event': 'optimize.activate'});
    this.resetSession();
  }

  // Also using for switching
  // between Potential / Authorized / Unauthorized user
  resetSession(): void {
    this._sessionReadyEmitter = new Subject<AnalyticSession>();
    this._sessionId = '';
  }

  emitCommonPageViewed({ path }: PageViewParam): void {
    this._gtag('config', AppConfig.gtagId, { page_path: path });
    this._fbq('track', 'PageView');
    this._sendPageViewed(path);
  }

  emitEvent(options: EventLogOptions): void {
    this._emitEvent(options);
  }

  emitCommonEvent(eventName: string, options?: EventOptions): void {
    this._emitGoogleEvent(eventName, options);
    this._emitFacebookEvent(eventName);
  }

  putPotentialUser(potentialUser: Partial<CreateUserReq> | SignUpDetails, userId?: string): Observable<null> {
    // AFTER REGISTRATION
    if (userId) this.emitCommonEvent('Application Submitted', { label: 'NewApp', category: 'Application Form 1.0.0' });

    const requestBody = {
      id: this._sessionId,
      user: userId,
      potentialUser,
    };
    const request = (body) => this._apiSrv.put('session/potential-user', body);
    if (this._sessionId) return request(requestBody);

    // WAIT until session registration complete
    return new Observable((subscriber) => {
      this._sessionReadyEmitter.subscribe((session) => {
        request({ ...requestBody, id: session._id }).subscribe((data) => {
          subscriber.next(data);
          subscriber.complete();
        });
      });
    });
  }

  private _registerSession(initialPagePath: string): void {
    this._getFingerprint().then((fingerprint) => {
      const body = {
        fingerprint,
        appVersion: AppConfig.appVersion,
        pagePath: initialPagePath,
      };
      this._apiSrv.post('session/register', body).subscribe((res: AnalyticSession) => {
        this._sessionId = res._id;
        this._sessionReadyEmitter.next(res);
        this._sessionReadyEmitter.complete();
      });
    });
  }

  private _getFingerprint(): Promise<FingerprintReq> {
    return new Promise((resolve, reject) => {
      const runRecognition = () => {
        if (this._fingerprintData) return resolve(this._fingerprintData);

        const options: Options = {
          // below markers might affect recognition
          // *plugins disabled in incognito mode...
          excludes: {
            plugins: true,
            localStorage: true,
            adBlock: true,
            // screenResolution: true,
            availableScreenResolution: true,
            enumerateDevices: true,
            pixelRatio: true,
            doNotTrack: true,
          },
          preprocessor: (key, value) => (value !== 'not available' ? value : undefined),
        };

        Fingerprint2.getPromise(options)
          .then((components) => {
            const data: FingerprintReq = {
              hashId: undefined,
              userAgent: undefined,
              language: undefined,
              deviceMemory: undefined,
              hardwareConcurrency: undefined,
              screenResolution: undefined,
              timezoneOffset: undefined,
              timezone: undefined,
            };
            let valuesStr = '';

            components.forEach(({ key, value }) => {
              if (selectKeys.includes(key)) data[key] = value;
              // exclude minor versions
              if (key === 'userAgent') {
                value = value.replace(/(\d+\.\d+\.\d+\.\d+)/, (a) => a.match(/^(\d+\.\d+)/)[0] || a);
              }
              valuesStr += value;
            });
            data.hashId = String(Fingerprint2.x64hash128(valuesStr, 31));

            this._fingerprintData = data;
            resolve(data);
          })
          .catch(reject);
      };

      if ((window as any).requestIdleCallback) {
        (window as any).requestIdleCallback(runRecognition);
      } else {
        setTimeout(runRecognition, 500);
      }
    });
  }

  private _sendPageViewed(pagePath: string): void {
    if (this._sessionId) {
      this._apiSrv.put('session/viewed-page', { id: this._sessionId, pagePath }).subscribe();
    } else {
      this._registerSession(pagePath);
    }
  }

  private _emitEvent(options: EventLogOptions) {
    this._apiSrv.post('event-log', options).subscribe();
  }

  private _emitGoogleEvent(eventName: string, options: EventOptions = {}): void {
    this._gtag('event', eventName, {
      event_category: options.category,
      event_label: options.label,
      event_action: options.action || null,
      event_value: options.value || null,
    });
  }

  private _emitFacebookEvent(eventName: string): void {
    this._fbq('trackCustom', eventName);
  }

  private _gtag(...args) {
    if (typeof gtag !== 'undefined') gtag(...args);
  }

  private _fbq(...args) {
    // because we apply facebook metrics only on prod
    if (typeof fbq !== 'undefined') fbq(...args);
  }
}
