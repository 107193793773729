import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { ActiveUserFacade } from '@shared/modules/active-user/store/active-user.facade';
import { UserState } from '@shared/modules/active-user/store/active-user.state';
import { Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({ providedIn: 'root' })
export class EnterPlatformGuard implements CanLoad {
  constructor(private authService: AuthService, private activeUserFacade: ActiveUserFacade) {}

  canLoad(): Observable<boolean> | boolean {
    if (!this.authService.isLoggedIn()) {
      return false;
    }

    return this.activeUserFacade.activeUser$
      .pipe(
        filter((user: UserState) => !!user.email),
        take(1)
      )
      .pipe(map((user: UserState) => !user.agreement.profileCompleted));
  }
}
