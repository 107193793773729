import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  message = 'Logged out successfully';

  constructor(private authSrv: AuthService) {}

  ngOnInit(): void {
    /**
     *  Using only for logout by WP
     */
    this.authSrv.logOut(false).subscribe(
      () => window.close(),
      () => (this.message = 'Log out failed.')
    );
  }
}
