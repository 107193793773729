export enum AuthorizedContentModule {
  CONVERSATION = 'CONVERSATION',
  MEMBER_SEARCH = 'MEMBER_SEARCH',
  PROFILE_GENERAL = 'PROFILE_GENERAL',
  PROFILE_CAREER = 'PROFILE_CAREER',
  PROFILE_ABOUT_ME = 'PROFILE_ABOUT_ME',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PROFILE_BILLING = 'PROFILE_BILLING',
  PROFILE_PASSWORD = 'PROFILE_PASSWORD',
  REMOTE_LINK = 'REMOTE_LINK',
}

export interface NavSection {
  title: string;
  items: {
    title: string;
    path: string;
    remoteLink?: boolean;
    module: AuthorizedContentModule;
  }[];
}

export enum SidenavState {
  expanded = 'expanded',
  collapsed = 'collapsed',
}

export enum SidenavSubmenu {
  settings = 'settings',
  communities = 'communities',
  conversations = 'conversations',
}
