import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InterceptorLoaderApiService {
  private readonly _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly isLoading$: Observable<boolean> = this._isLoading$.asObservable();

  constructor() {}

  changeState(isLoading: boolean): void {
    this._isLoading$.next(isLoading);
  }

  get isLoading(): boolean {
    return this._isLoading$.value;
  }
}
