import { Injectable } from '@angular/core';
import { SearchParams } from '@shared/models';
import { Post } from '@shared/models/post.models';
import { PaginatedData, QueryParams } from '@shared/models/query-params';
import { ApiService } from '@shared/services/http/api/api.service';
import { Observable } from 'rxjs';
import { ConversationsApi } from '../api/conversations.api';

@Injectable()
export class ConversationsService implements ConversationsApi {
  constructor(private apiService: ApiService) {}

  getPost(id: string): Observable<Post> {
    return this.apiService.get(`post/${id}`);
  }

  getPosts(queryParams: QueryParams): Observable<PaginatedData<Post>> {
    return this.apiService.get('posts/paginated', { params: queryParams });
  }

  getNewPostsCount(searchParams: SearchParams): Observable<number> {
    return this.apiService.get('posts/count', { params: searchParams });
  }

  deletePost(id: string): Observable<Post> {
    return this.apiService.delete(`post/${id}`);
  }

  follow(id: string): Observable<{ follow: boolean }> {
    return this.apiService.patch(`post/${id}/follow`, { follow: true });
  }

  unfollow(id: string): Observable<{ follow: boolean }> {
    return this.apiService.patch(`post/${id}/follow`, { follow: false });
  }

  pinPost(postId: string, communityId: string): Observable<any> {
    return this.apiService.patch(`post/${postId}/pin`, { pin: true, community: communityId });
  }

  unpinPost(postId: string, communityId: string): Observable<any> {
    return this.apiService.patch(`post/${postId}/pin`, { pin: false, community: communityId });
  }
}
