import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HmSnackbarComponent } from './hm-snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarService } from '@shared/components/hm-snackbar/service/snackbar.service';
import { MaterialModule } from '@material/material.module';

@NgModule({
  declarations: [HmSnackbarComponent],
  imports: [CommonModule, MaterialModule],
  exports: [MatSnackBarModule],
  providers: [SnackbarService],
})
export class HmSnackbarModule {}
