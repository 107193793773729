import { Component, ViewEncapsulation } from '@angular/core';
import { AppConfig } from '@config';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { AnalyticsService } from '@shared/services/analytics/analytics.service';
import { RouteLoadingIndecator } from '@shared/services/route-loading-indecator/route-loading-indecator.service';
import { ActiveUserFacade } from '@shared/modules/active-user/store/active-user.facade';
import { AppEventsApi } from './core/app-events/api/app-events.api';
import { AppEvent, AppEventType } from './core/app-events/models/app-events.models';
import { Observable } from 'rxjs';
import { InterceptorLoaderApiService } from './core/api/interceptor-loader-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'heymama';
  AppConfig = AppConfig;

  readonly isLoading$: Observable<boolean> = this.interceptorLoaderApi.isLoading$;

  constructor(
    private _router: Router,
    private _analyticsService: AnalyticsService,
    private routeLoadingIndecator: RouteLoadingIndecator,
    private appEventsApi: AppEventsApi,
    private activeUserFacade: ActiveUserFacade,
    private interceptorLoaderApi: InterceptorLoaderApiService
  ) {
    this._router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.routeLoadingIndecator.start();
      }

      if (event instanceof NavigationEnd) {
        this.routeLoadingIndecator.stop();
        this._analyticsService.emitCommonPageViewed({ path: event.urlAfterRedirects });
      }
      if (event instanceof NavigationCancel || event instanceof NavigationError) {
        this.routeLoadingIndecator.stop();
      }
    });

    this.subscribeToAppEvents();
  }

  private subscribeToAppEvents(): void {
    this.appEventsApi.events$.subscribe((event: AppEvent) => {
      if (event.type === AppEventType.logOut) {
        this.handleLogOutEvent();
      }
    });
  }

  private handleLogOutEvent(): void {
    this.activeUserFacade.logOut();
  }
}
