import { Observable } from 'rxjs';
import { SidenavState } from '../models/authorized-content.models';

export abstract class AuthorizedContentWrapperApi {
  readonly sidenavState$: Observable<SidenavState>;

  readonly updateCommunitiesList$: Observable<void>;

  abstract toggleSidenav(): void;

  abstract changeState(state: SidenavState): void;

  abstract updateCommunitiesList(): void;

  abstract get sidenavState(): SidenavState;
}
