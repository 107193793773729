import { Injectable } from '@angular/core';
import { AvatarUploaderApi } from '@shared/api/avatar-uploader.api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AvatarUploaderService implements AvatarUploaderApi {
  private readonly _uploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly uploading$: Observable<boolean> = this._uploading$.asObservable();

  private readonly _uploadAvatar$: Subject<any> = new Subject();
  readonly uploadAvatar$: Observable<any> = this._uploadAvatar$.asObservable();

  private readonly _avatarUploaded$: Subject<void> = new Subject();
  readonly avatarUploaded$: Observable<void> = this._avatarUploaded$.asObservable();

  constructor() {}

  upload(payload: any): void {
    this._uploadAvatar$.next(payload);
    this._uploading$.next(true);
  }

  avatarUploaded(): void {
    this._avatarUploaded$.next();
    this._uploading$.next(false);
  }
}
