import { SidenavState, SidenavSubmenu } from '../models/authorized-content.models';

export abstract class SidenavStateApi {
  abstract getSidenavState(): SidenavState;

  abstract setSidenavState(state: SidenavState): void;

  abstract get expandedSubmenu(): SidenavSubmenu;

  abstract set expandedSubmenu(submenu: SidenavSubmenu);
}
