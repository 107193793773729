import { Observable } from 'rxjs';
import {
  ConversationsNotificationsCounters,
  ResetCountersDto,
} from '@shared/interfaces/conversations-notifications-counters.interfaces';
import { DefaultConversationsFilterValue } from '@shared/models/conversations.models';

export abstract class ConversationsNotificationsCountersApi {
  abstract getCounters(timeInterval?: number): Observable<ConversationsNotificationsCounters>;

  abstract resetCounters(counters: ResetCountersDto): Observable<ConversationsNotificationsCounters>;

  abstract filterToCounterMap(counter: DefaultConversationsFilterValue): ResetCountersDto;
}
