import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { activeUserFeatureName } from './active-user.reducer';
import {
  ActiveUserState,
  AvatarInterface,
  BillingInformation,
  Connection,
  Demographic,
  Education,
  PaymentHistory,
  Personality,
  SubscriptionInfo,
} from './active-user.state';
import { Career, socialNetwork } from '../../../../modules/profile/store/edit-membership.interfaces';
import {
  CareerJobInput,
  CareerJobPrivacyInput,
} from '../../../../modules/profile/pages/edit-membership-career/interfaces/edit-membership-career.interface';
import {
  Mamahood,
  MembershipIntroLocation,
} from '../../../../modules/membership/pages/membership-intro/interfaces/membership-intro.interface';
import { MembershipAboutMeWebsite } from '../../../../modules/membership/pages/membership-about-me/interfaces/membership-about-me.interface';
import { UserStatus } from '@shared/modules/active-user/interfaces/user-status';
import { Support } from '../../../../modules/sign-up/pages/sign-up-details/interfaces/sign-up-details.interface';

const selectActiveUserFeatureState = createFeatureSelector<ActiveUserState>(activeUserFeatureName);

export const SelectActiveUser = createSelector(selectActiveUserFeatureState, (state) =>
  JSON.parse(JSON.stringify(state))
);

export const SelectEditProfileMessage = createSelector(SelectActiveUser, (state) =>
  state && state.editProfileMessage ? state.editProfileMessage : { isOpen: false, message: '' }
);

export const selectProfileStatus = createSelector(SelectActiveUser, (state) =>
  state && state.status ? state.status : null
);

export const selectFirstName = createSelector(SelectActiveUser, (state): string => state.firstName);

export const selectLastName = createSelector(SelectActiveUser, (state): string => state.lastName);

export const selectBirthday = createSelector(SelectActiveUser, (state): string => state.birthday);

export const selectEmail = createSelector(SelectActiveUser, (state): string => state.email);

export const selectEmails = createSelector(SelectActiveUser, (state): string[] => state.emails);

export const selectPhone = createSelector(SelectActiveUser, (state): string => state.phone);

export const selectLocation = createSelector(SelectActiveUser, (state): MembershipIntroLocation => state.location);

export const selectMamahood = createSelector(SelectActiveUser, (state): Mamahood[] => state.mamahood);

export const selectWebsites = createSelector(SelectActiveUser, (state): MembershipAboutMeWebsite[] => state.websites);

export const selectEducation = createSelector(SelectActiveUser, (state): Education => state.education);

export const selectDemographic = createSelector(SelectActiveUser, (state): Demographic => state.demographic);

export const selectPersonality = createSelector(SelectActiveUser, (state): Personality => state.personality);

export const selectSocialNetworks = createSelector(SelectActiveUser, (state): socialNetwork[] => state.socialNetworks);

export const selectConnection = createSelector(SelectActiveUser, (state): Connection => state.connection);

const selectPrivacy = createSelector(SelectActiveUser, (state) => {
  return state?.privacy?.profile ? state.privacy.profile : null;
});

const selectCareerSummary = createSelector(SelectActiveUser, (state) => {
  return state?.privacy?.profile ? state.privacy.profile : null;
});

export const selectEmailPrivacy = createSelector(selectPrivacy, (state) => (state?.email ? state.email : null));

export const selectPhonePrivacy = createSelector(selectPrivacy, (state) => (state?.phone ? state.phone : null));

export const selectPersonalityPrivacy = createSelector(selectPrivacy, (state) =>
  state?.personality ? state.personality : {}
);

export const selectDemographicPrivacy = createSelector(selectPrivacy, (state) => state?.demographic);

export const selectEmailsPrivacy = createSelector(selectPrivacy, (state) => state?.emails || []);

export const selectWebsitesPrivacy = createSelector(selectPrivacy, (state) => (state?.websites ? state.websites : {}));

export const selectConnectionPrivacy = createSelector(selectPrivacy, (state) =>
  state?.connection ? state.connection : {}
);

export const selectSocialNetworksPrivacy = createSelector(selectPrivacy, (state) =>
  state?.socialNetworks ? state.socialNetworks : []
);

export const selectEducationPrivacy = createSelector(selectPrivacy, (state) =>
  state?.education ? state.education : {}
);

export const selectMamahoodPrivacy = createSelector(selectPrivacy, (state) =>
  state?.mamahood ? state.mamahood : null
);

export const selectLocationPrivacy = createSelector(selectPrivacy, (state) =>
  state?.location ? state.location : null
);

export const selectEditProfileIntroPrivacy = createSelector(
  selectEmailPrivacy,
  selectLocationPrivacy,
  selectMamahoodPrivacy,
  selectPhonePrivacy,
  selectDemographicPrivacy,
  selectEmailsPrivacy,
  (emailPrivacy, locationPrivacy, mamahoodPrivacy, phonePrivacy, demographic, emails) => {
    return {
      emails,
      email: emailPrivacy ? emailPrivacy : { isPrivate: null },
      phone: phonePrivacy ? phonePrivacy : { isPrivate: null },
      location: locationPrivacy ? locationPrivacy : { isPrivate: null },
      mamahood: mamahoodPrivacy ? mamahoodPrivacy : { isPrivate: null },
      demographic: demographic ? demographic : { isPrivate: null },
    };
  }
);

export const selectEditProfileIntro = createSelector(
  SelectActiveUser,
  selectBirthday,
  selectEmail,
  selectPhone,
  selectLocation,
  selectMamahood,
  selectDemographic,
  (
    { firstName, lastName, emails },
    birthday,
    email,
    phone,
    location,
    mamahood,
    { children, parenthood, gender, nationality }
  ) => ({
    firstName,
    lastName,
    birthday,
    email,
    emails,
    phone,
    location,
    mamahood,
    children,
    parenthood,
    demographic: { gender, nationality },
  })
);

export const selectEditProfileCareerPrivacy = createSelector(selectPrivacy, (state) => {
  return state?.career ? state.career : [];
});

type SelectProfileCareerPayload = Omit<Career, 'jobs'> & { privacy: Record<keyof Career, { isPrivate: boolean }> } & {
  jobs: CareerJobInput[];
};

export const selectEditProfileCareer = createSelector(
  SelectActiveUser,
  selectEditProfileCareerPrivacy,
  (state, careerPrivacy): SelectProfileCareerPayload => {
    return state?.career
      ? {
          ...state.career,
          privacy: careerPrivacy,
          jobs: state.career.jobs.map((job, index) => {
            return {
              ...job,
              position: {
                value: job.position?._id,
                viewValue: job.position?.title,
              },
              industry: {
                value: job.industry?._id,
                viewValue: job.industry?.title,
              },
            };
          }),
        }
      : null;
  }
);

// export const selectEditProfileJobPrivacy = (index: number) =>
//   createSelector(selectEditProfileCareerPrivacy, (state) => {
//     return state?.career ? state.career[index] : null
//   })

export const selectActiveUserSupportInfo = createSelector(
  SelectActiveUser,
  (state): Support => {
    return state && state.support ? state.support : null;
  }
);

export const selectEditProfileAbout = createSelector(
  selectWebsites,
  selectSocialNetworks,
  selectEducation,
  selectDemographic,
  selectPersonality,
  selectConnection,
  (websites, socialNetworks, education, demographic, personality, connection): any => ({
    websites,
    socialNetworks,
    education,
    demographic,
    personality,
    connection,
  })
);

export const selectEditProfileAboutPrivacy = createSelector(
  selectPersonalityPrivacy,
  selectEducationPrivacy,
  selectSocialNetworksPrivacy,
  selectWebsitesPrivacy,
  selectConnectionPrivacy,
  (personality, education, socialNetworks, websites, connection) => ({
    personality,
    education,
    socialNetworks,
    websites,
    connection,
  })
);

export const selectEditProfileBilling = createSelector(SelectActiveUser, (state) =>
  state && state.payment ? state.payment : null
);

export const selectStatusReasonParams = createSelector(
  SelectActiveUser,
  (state, props: { status: UserStatus.PAUSED | UserStatus.CANCELED }) =>
    state.statusReasonParamsStructured[props.status]
);

export const selectAvatar = createSelector(
  SelectActiveUser,
  (state): AvatarInterface => (state && state.avatar ? state.avatar : null)
);

export const selectPaymentHistory = createSelector(
  SelectActiveUser,
  (state): PaymentHistory => state?.paymentHistory || null
);

export const selectBillingInformation = createSelector(
  SelectActiveUser,
  (state): BillingInformation => (state && state.billingInformation ? state.billingInformation : null)
);

export const selectSubscriptionInfo = createSelector(SelectActiveUser, (state): SubscriptionInfo => state.subscription);
