import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SelectActiveUser, selectSubscriptionInfo } from './active-user.selectors';
import { ActiveUserState, SubscriptionInfo, UserState } from './active-user.state';
import * as ActiveUserActions from './active-user.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Action, ActionCreator } from '@ngrx/store/src/models';

@Injectable({ providedIn: 'root' })
export class ActiveUserFacade {
  readonly activeUser$: Observable<ActiveUserState> = this.store.select(SelectActiveUser);
  readonly subacribtion$: Observable<SubscriptionInfo> = this.store.select(selectSubscriptionInfo);
  readonly logOutAction$: Observable<SubscriptionInfo> = this.actions$.pipe(ofType(ActiveUserActions.logOut));

  constructor(private actions$: Actions, private store: Store) {}

  logOut(): void {
    this.store.dispatch(ActiveUserActions.logOut());
  }

  loadActiveUser(): void {
    this.store.dispatch(ActiveUserActions.loadActiveUser());
  }

  upsertUser(payload: UserState): void {
    this.store.dispatch(ActiveUserActions.upsertUser({ payload }));
  }

  getAction<T = Action>(action: ActionCreator): Observable<T> {
    return this.actions$.pipe(ofType(action));
  }
}
