import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorizedContentWrapperApi } from '../api/authorized-content-wrapper.api';
import { SidenavState } from '../models/authorized-content.models';

@Injectable()
export class AuthorizedContentWrapperService implements AuthorizedContentWrapperApi {
  private readonly _sidenavState$: BehaviorSubject<SidenavState> = new BehaviorSubject(SidenavState.expanded);
  readonly sidenavState$: Observable<SidenavState> = this._sidenavState$.asObservable();

  private readonly _updateCommunitiesList$: Subject<void> = new Subject();
  readonly updateCommunitiesList$: Observable<void> = this._updateCommunitiesList$.asObservable();

  toggleSidenav(): void {
    const sidenavState: SidenavState = this._sidenavState$.value;
    this._sidenavState$.next(sidenavState === SidenavState.expanded ? SidenavState.collapsed : SidenavState.expanded);
  }

  changeState(state: SidenavState): void {
    this._sidenavState$.next(state);
  }

  updateCommunitiesList(): void {
    this._updateCommunitiesList$.next();
  }

  get sidenavState(): SidenavState {
    return this._sidenavState$.value;
  }
}
