import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { ActiveUserFacade } from '@shared/modules/active-user/store/active-user.facade';
import { UserState } from '@shared/modules/active-user/store/active-user.state';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private activeUserFacade: ActiveUserFacade, private router: Router) {}

  canLoad() {
    return this.check();
  }

  canActivate() {
    return this.check();
  }

  canActivateChild() {
    return this.check();
  }

  private check() {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['auth/login']);
      return false;
    }

    return this.activeUserFacade.activeUser$
      .pipe(
        filter((user: UserState) => !!user.email),
        take(1)
      )
      .pipe(
        map((user: UserState) => {
          if (user.agreement.profileCompleted) return true;

          this.router.navigateByUrl('/enter-platform');
          return false;
        })
      );
  }
}
