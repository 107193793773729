import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar'
import { SnackbarMessage } from '@shared/components/hm-snackbar/const/snackbar-message'
import { HmSnackbarComponent } from '@shared/components/hm-snackbar/hm-snackbar.component'

export interface Snackbar {
  type: 'error' | 'success' | 'info' | 'warning';
  message: string;
  title?: string;
  _snackRef?: MatSnackBarRef<any>;
}


@Injectable()
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  open(data?: Snackbar, duration?: number) {
    data = data || this.resetData('info');

    const config = new MatSnackBarConfig();
    config.panelClass = ['hm-snackbar', 'hm-snackbar-' + data.type];
    config.duration = duration || 10000;
    config.data = data;
    // Note: Position loses on chunk error (Fixed via CSS)
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'right';

    config.data._snackRef = this.snackBar.openFromComponent(HmSnackbarComponent, config);
  }

  success(message = 'Success', duration = 3500) {
    this.open({ type: 'success', message }, duration);
  }

  warning(message = SnackbarMessage.FORM_WARNING, duration = 3500) {
    this.open({ type: 'warning', message }, duration);
  }

  error(message = SnackbarMessage.ERROR_DEFAULT, duration = 3500) {
    this.open({ type: 'error', message }, duration);
  }

  info(message = SnackbarMessage.ERROR_DEFAULT, duration = 2500) {
    this.open({ type: 'info', message }, duration);
  }

  resetData(type: Snackbar['type']): Snackbar {
    return { title: '', message: '', type, _snackRef: null };
  }
}
