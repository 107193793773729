import { environment } from '../environments/environment';
import { EnvInterface } from '@shared/interfaces/env.interface';

interface ConfigInterface extends EnvInterface {
  appVersion: string;
}

export const AppConfig: ConfigInterface = {
  ...environment,
  appVersion: '1.0.1.33',
};
