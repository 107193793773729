import { Injectable } from '@angular/core';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, delayWhen, retryWhen, tap } from 'rxjs/operators';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';

import { SnackbarMessage, SnackbarService } from '@shared/components/hm-snackbar';
import { HttpStatus } from '@shared/consts';
import { ApiInputOptions } from '@shared/services/http/api/api.service';

import { AppEventsApi } from 'src/app/core/app-events/api/app-events.api';
import { AppEvent, AppEventType } from 'src/app/core/app-events/models/app-events.models';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { InterceptorLoaderApiService } from 'src/app/core/api/interceptor-loader-api.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private appEventsApi: AppEventsApi,
    private authService: AuthService,
    private _snackbarSvc: SnackbarService,
    private interceptorLoaderApi: InterceptorLoaderApiService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen((errors: Observable<any>) => {
        return errors.pipe(
          delayWhen((error: HttpErrorResponse, attemptsNumber: number) => {
            if (attemptsNumber >= 3 || error.status !== 0) {
              if (this.interceptorLoaderApi.isLoading) {
                this.interceptorLoaderApi.changeState(false);
              }
              return throwError(error);
            }

            if (!this.interceptorLoaderApi.isLoading) {
              this.interceptorLoaderApi.changeState(true);
            }
            return timer(1500);
          })
        );
      }),

      tap((response: HttpResponse<any>) => {
        if (response instanceof HttpResponseBase) {
          if (this.interceptorLoaderApi.isLoading) {
            this.interceptorLoaderApi.changeState(false);
          }
        }
      }),

      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatus.FORBIDDEN) {
          this.handleForbiddenError();
        }

        let message = SnackbarMessage.ERROR_DEFAULT;

        if (!window.navigator.onLine) {
          // Network error occurred.
          message = SnackbarMessage.NO_NETWORK;
        } else if (error.error) {
          if (this.shouldSkipError(request, error)) return throwError(error);

          message = error.error.message;
          const statusCode = error.error.statusCode;

          // expired or invalid token handled at token-interceptor
          if (statusCode === HttpStatus.UNAUTHORIZED) return throwError(error);

          // not found route or bed request
          if ([HttpStatus.INTERNAL_SERVER_ERROR].includes(statusCode)) {
            message = SnackbarMessage.ERROR_DEFAULT;
          }
        }

        this._snackbarSvc.error(message);
        return throwError(error);
      })
    );
  }

  // skip specific error
  shouldSkipError(request: HttpRequest<any>, { error }: HttpErrorResponse): boolean {
    const excludeErrors: ApiInputOptions['excludeErrors'] =
      JSON.parse(request.headers.get('excludeErrors') || '') || [];
    return excludeErrors.some((rule) => rule === '*' || String(error[rule.key]) === rule.value);
  }

  private handleForbiddenError(): void {
    if (!this.authService.isLoggedIn()) return;

    this.appEventsApi.dispatchEvent(new AppEvent(AppEventType.logOut));
  }

  /**
   * Custom ErrorHandler to increase UX
   * will fire only in case of Chunk error(core error on No Internet)
   */
  handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    if (!error.error) {
      console.error('custom core ERROR', error);

      if (window.navigator.onLine) {
        this._snackbarSvc.error(`${SnackbarMessage.ERROR_DEFAULT} <br> ${SnackbarMessage.NEEDS_RELOAD}`, 20000);
      } else {
        // if (error+'').includes('Error: Loading chunk')
        this._snackbarSvc.error(SnackbarMessage.NO_NETWORK, 6000);
      }
    }

    return throwError(error);
  }
}
