import { createReducer, on } from '@ngrx/store';
import * as AppState from '../../../../store/app.state';
import * as ActiveUserActions from './active-user.actions';
import { ActiveUserState } from './active-user.state';

export const activeUserFeatureName = 'activeUser';

export interface State extends AppState.AppState {
  activeUser: ActiveUserState;
}

const initialState: ActiveUserState = new ActiveUserState();

export const activeUserReducer = createReducer<ActiveUserState>(
  initialState,
  on(ActiveUserActions.loadActiveUserSuccess, (state, action): any => {
    return {
      ...state,
      ...action.user,
    };
  }),
  on(ActiveUserActions.upsertUser, (state, action): any => ({ ...state, ...action.payload })),
  on(ActiveUserActions.deleteUserData, (): ActiveUserState => initialState),
  on(
    ActiveUserActions.setProfileMessage,
    (state, { payload }): ActiveUserState => {
      return { ...state, editProfileMessage: payload };
    }
  ),
  on(
    // better use separated reducer to decrease fetch
    ActiveUserActions.clearEditProfileMessage,
    (state, action): ActiveUserState => {
      return {
        ...state,
        editProfileMessage: { isOpen: false, message: null, timeOut: null },
      };
    }
  ),
  on(
    ActiveUserActions.loadStatusReasonParamsSuccess,
    (state, { payload }): ActiveUserState => {
      const statusReasonParamsStructured = {
        ...state.statusReasonParamsStructured,
        ...payload,
      };
      return { ...state, statusReasonParamsStructured };
    }
  ),
  on(ActiveUserActions.loadPaymentsSuccess, (state, action): any => {
    const { currPage, data } = action.payments;
    return {
      ...state,
      paymentHistory: {
        ...action.payments,
        // TODO Temporary, should be pure!
        data: currPage === 1 ? data : state.paymentHistory.data.concat(data),
      },
    };
  }),
  on(ActiveUserActions.loadBillingInfoSuccess, (state, action): any => {
    return {
      ...state,
      billingInformation: action.billingInformation,
    };
  })

  // on(
  //   ActiveUserActions.updateStatusSuccess,
  //   (state, action): ActiveUserState => {
  //     console.log(action)
  //     return { ...state, payment: action.payment }
  //   }
  // )
  // on(
  //   ActiveUserActions.updateActiveUser,
  //   (state): ActiveUserState => {
  //     return {
  //       ...state,
  //       editProfileMessage: { isOpen: false, message: null, timeOut: null },
  //     }
  //   }
  // )
);
