import { Injectable } from '@angular/core';
import { SidenavStateApi } from '../api/sidenav-state.api';
import { SidenavState, SidenavSubmenu } from '../models/authorized-content.models';

const SIDENAV_STATE_KEY: string = 'SIDENAV_STATE';
const EXPANDED_SIDENAV_SUBMENU_KEY: string = 'EXPANDED_SIDENAV_SUBMENU_KEY';

@Injectable()
export class SidenavStateService implements SidenavStateApi {
  constructor() {}

  getSidenavState(): SidenavState {
    return (localStorage.getItem(SIDENAV_STATE_KEY) as SidenavState) || SidenavState.expanded;
  }

  setSidenavState(state: SidenavState): void {
    localStorage.setItem(SIDENAV_STATE_KEY, state);
  }

  get expandedSubmenu(): SidenavSubmenu {
    return localStorage.getItem(EXPANDED_SIDENAV_SUBMENU_KEY) as SidenavSubmenu;
  }

  set expandedSubmenu(submenu: SidenavSubmenu) {
    localStorage.setItem(EXPANDED_SIDENAV_SUBMENU_KEY, submenu);
  }
}
