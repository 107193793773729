// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvInterface } from '@shared/interfaces/env.interface';

export const environment: EnvInterface = {
  production: false,
  env: 'development',
  hmr: false,
  // apiUrl: 'http://ec2-18-191-255-36.us-east-2.compute.amazonaws.com/api/',
  apiUrl: 'https://dev-api-hm.wlcmapps.com/api/',
  whitelistedDomains: ['http://ec2-18-191-255-36.us-east-2.compute.amazonaws.com', 'https://dev-api-hm.wlcmapps.com'],
  stripeKey: 'pk_test_KqJ2xTxFdzu9DxLmnyZoeaGg',
  gtagId: 'UA-174758122-1',
  wpDomain: 'https://dev.heymama.co/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
