import { Injectable } from '@angular/core';
import { ConversationsNotificationsCountersApi } from '@shared/api/conversations-notifications-counters.api';
import {
  ConversationsNotificationsCounters,
  ResetCountersDto,
} from '@shared/interfaces/conversations-notifications-counters.interfaces';
import { interval, merge, Observable, of, Subject } from 'rxjs';
import { exhaustMap, filter, startWith, tap } from 'rxjs/operators';
import { ApiService } from '../http/api/api.service';
import { DefaultConversationsFilterValue } from '@shared/models/conversations.models';
import { DEFAULT_CONVERSATIONS_FILTER } from '@shared/consts/conversations.constants';

const DEFAULT_TIME_INTERVAL: number = 20 * 1000;

@Injectable()
export class ConversationsNotificationsCountersService implements ConversationsNotificationsCountersApi {
  private readonly _updateCounters$: Subject<ConversationsNotificationsCounters> = new Subject();

  constructor(private apiService: ApiService) {}

  getCounters(timeInterval: number = DEFAULT_TIME_INTERVAL): Observable<ConversationsNotificationsCounters> {
    return merge(interval(timeInterval).pipe(startWith(0)), this._updateCounters$)
      .pipe(filter(() => navigator.onLine))
      .pipe(
        exhaustMap((value: ConversationsNotificationsCounters | number) =>
          value instanceof Object ? of(value) : this.apiService.get('notifications/counters')
        )
      );
  }

  resetCounters(counters: ResetCountersDto): Observable<ConversationsNotificationsCounters> {
    return this.apiService
      .patch('notifications/counters', counters)
      .pipe(tap((counters: ConversationsNotificationsCounters) => this._updateCounters$.next(counters)));
  }

  filterToCounterMap(counter: DefaultConversationsFilterValue): ResetCountersDto {
    switch (counter) {
      case DEFAULT_CONVERSATIONS_FILTER.latest: {
        return { posts: { latest: true } };
      }
      case DEFAULT_CONVERSATIONS_FILTER.myConversations: {
        return { replies: { ownPosts: true } };
      }
      case DEFAULT_CONVERSATIONS_FILTER.following: {
        return { replies: { followedPosts: true } };
      }
      case DEFAULT_CONVERSATIONS_FILTER.mentions: {
        return { mentions: { latest: true } };
      }
    }
  }
}
