export class CareerJob {
  title: string;
  companyName: string;
  endDate: string;
  startDate: string;
  website: string;
  industry: Industry = new Industry();
  position: Position = new Position();
  isCurrent: boolean;
}

export class Career {
  jobs: CareerJob[] = [];
  description: string;
  employment: string;
  employmentType: string;
  businessStage: string;
  expertise: Expertise;
}

export class Expertise {
  need: { description: string; experiences: any[] };
  offer: { description: string; experiences: any[] };
}

class Industry {
  _id: string;
  title: string;
}

class Position {
  _id: string;
  title: string;
}

export class EditMembershipAbout {
  websites: Website[];
  socialNetworks: socialNetwork[];
}

export class Website {
  url = '';
  ownership = '';
}

export interface socialNetwork {
  urlId: string;
  title: string;
}
