import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AppEvent } from '../models/app-events.models';

@Injectable()
export class AppEventsService {
  private readonly _events$: Subject<AppEvent> = new Subject();
  readonly events$: Observable<AppEvent> = this._events$.asObservable().pipe(shareReplay());

  dispatchEvent(event: AppEvent): void {
    this._events$.next(event);
  }
}
