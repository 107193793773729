import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouteLoadingIndecator {
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoading$: Observable<boolean> = this._isLoading$.asObservable();

  start(): void {
    this._isLoading$.next(true);
  }

  stop(): void {
    this._isLoading$.next(false);
  }

  get isLoading(): boolean {
    return this._isLoading$.getValue();
  }
}
