import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HideProdDirective } from './hide-prod.directive'

@NgModule({
  declarations: [HideProdDirective],
  imports: [CommonModule],
  exports: [HideProdDirective],
})
export class HideProdModule {}
