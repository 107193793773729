import { Injectable } from '@angular/core';
import { Reply } from '@shared/models/reply.models';
import { BehaviorSubject, Observable } from 'rxjs';
import { ViewPostFooterStateApi } from '../api/view-post-footer-state.api';
import { FooterState } from '../interfaces/view-post-footer-state.interfaces';

@Injectable()
export class ViewPostFooterStateService implements ViewPostFooterStateApi {
  private readonly _state$: BehaviorSubject<FooterState> = new BehaviorSubject(FooterState.collapsed);
  private readonly _editReply$: BehaviorSubject<Reply> = new BehaviorSubject(null);
  readonly state$: Observable<FooterState> = this._state$.asObservable();
  readonly editReply$: Observable<Reply> = this._editReply$.asObservable();

  changeState(state: FooterState): void {
    this._state$.next(state);
  }

  editReply(reply: Reply): void {
    this._editReply$.next(reply);
  }

  get state(): FooterState {
    return this._state$.value;
  }

  get currentReply(): Reply {
    return this._editReply$.value;
  }
}
