import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HmSpinnerComponent } from '@shared/components/hm-spinner/hm-spinner.component';

@NgModule({
  declarations: [HmSpinnerComponent],
  imports: [CommonModule],
  exports: [HmSpinnerComponent],
})
export class HmSpinnerModule {}
