import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpRoute } from './modules/sign-up/const';
import { AuthRoute } from './modules/auth/const';
import { LoggedInRedirectGuard } from './modules/auth/guards/logged-in-redirect.guard';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { LogoutComponent } from './modules/auth/pages/logout/logout.component';
import { EnterPlatformGuard } from './modules/enter-platform/guards/enter-platform.guard';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/authorized-content-wrapper/authorized-content-wrapper.module').then(
        (m) => m.AuthorizedContentWrapperModule
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'view-post',
    loadChildren: () => import('./modules/view-post/view-post.module').then((m) => m.ViewPostModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'community-standards',
    loadChildren: () =>
      import('./modules/community-standards/community-standards.module').then((m) => m.CommunityStandardsModule),
  },
  {
    path: SignUpRoute.ROOT,
    loadChildren: () => import('./modules/sign-up/sign-up.module').then((m) => m.SignUpModule),
    canLoad: [LoggedInRedirectGuard],
  },
  {
    path: AuthRoute.ROOT,
    loadChildren: () => import('./modules/auth').then((m) => m.AuthModule),
    canLoad: [LoggedInRedirectGuard],
  },
  {
    path: 'create-post',
    loadChildren: () => import('./modules/post-maker/post-maker.module').then((e) => e.PostMakerModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'edit-post/:id',
    loadChildren: () => import('./modules/post-maker/post-maker.module').then((e) => e.PostMakerModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'enter-platform',
    loadChildren: () => import('./modules/enter-platform/enter-platform.module').then((m) => m.EnterPlatformModule),
    canLoad: [EnterPlatformGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '**',
    redirectTo: AuthRoute.LOGIN,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
