import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, Inject } from '@angular/core'
import { Snackbar } from '@shared/components/hm-snackbar/service/snackbar.service'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'

@Component({
  selector: 'app-hm-snackbar',
  templateUrl: './hm-snackbar.component.html',
  styleUrls: ['./hm-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HmSnackbarComponent implements OnInit {
  @ViewChild('snack') snack: ElementRef;
  content: Snackbar = {
    title: '',
    message: '',
    type: 'info',
    _snackRef: null
  };

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public passedData: Snackbar,
  ) {}

  ngOnInit() {
    if (this.passedData) this.content = this.passedData;
  }

  dismiss() {
    this.passedData._snackRef.dismiss();
    // workaround to remove element from DOM on chunk error
    this.snack.nativeElement.remove();
  }
}
