import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'hm-spinner',
  templateUrl: './hm-spinner.component.html',
  styleUrls: ['./hm-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HmSpinnerComponent implements OnInit {
  @Input() diameter = 100; // px
  @Input() fullscreen = false;
  @Input() opaque = false;

  constructor() {}

  ngOnInit(): void {}
}
