import { Testimonial } from '../components/testimonials/testimonials.component';

export const testimonialsIntroPage: Testimonial[] = [
  {
    image: 'assets/images/testimonial-1.svg',
    comment:
      'HeyMama provides the support, inspiration and acknowledgement every mama needs to build her own space outside motherhood.',
    author: 'Mimi Chan',
    position: 'CEO and Founder at Littlefund',
    coreCity: 'San Francisco',
    industry: 'Financedianne.jpg',
  },
  {
    image: 'assets/images/testimonial-2.svg',
    comment:
      'When I needed to pitch my business, I reached out to the community, and within hours I was on the phone with a VC coaching me.',
    author: 'Dianne Myles',
    position: 'CEO at Dianne Myles LLC',
    coreCity: 'Denver',
    industry: 'Media',
  },
];

export const testimonialsDetailsPage: Testimonial[] = [
  {
    image: '../../../../assets/temp-images/testimonial-3.jpg',
    comment:
      'Launching a business during the world pandemic has been hard. Being a mother of two young kids at home during all of this is also hard. I am so thankful for the mentorship and friendship I have found by being a HeyMama member. I have a community of mamas on a mission to lift me and my business up.',
    author: 'Terra LaRock',
    position: 'CEO and Founder at Mindful Mamas',
    coreCity: 'Denver',
    industry: 'Wellness',
  },
  {
    image: '../../../../assets/temp-images/testimonial-4.jpg',
    comment:
      'To say that being a working mom is hard would be an understatement. HeyMama’s community of inspirational women provides the support system moms need to succeed in both personal and professional endeavors.',
    author: 'Divya Gugani',
    position: 'Co-Founder and CEO at Wander Beauty',
    coreCity: 'NYC',
    industry: 'Beauty',
  },
];
