import { Reply } from '@shared/models/reply.models';
import { Observable } from 'rxjs';
import { FooterState } from '../interfaces/view-post-footer-state.interfaces';

export abstract class ViewPostFooterStateApi {
  readonly state$: Observable<FooterState>;

  readonly editReply$: Observable<Reply>;

  abstract changeState(state: FooterState): void;

  abstract editReply(reply: Reply): void;

  abstract get state(): FooterState;

  abstract get currentReply(): Reply;
}
